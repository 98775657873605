import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

import Notifications from './Notifications'
import withMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_NOTIFICATION = gql`
  query {
    notifications {
      id
      message
      heading
      type
      created_at
    }
  }
`

const SEND_PUSH = gql`
  mutation sendPushNotificationToAll($input: PushNotificationInput) {
    sendPushNotificationToAll(input: $input) {
      status
      message
    }
  }
`

const columns = [
  {
    title: 'Заголовок',
    dataIndex: 'heading',
    width: '30%',
  },
  {
    title: 'Текст',
    dataIndex: 'message',
    width: '50%',
  },
  {
    title: 'Дата отправки',
    dataIndex: 'created_at',
    width: '20%',
  },
]

const NotificationsContainer = () => {
  const [notifications, setNotifications] = React.useState([])
  const [heading, setHeading] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [type, setType] = React.useState('')
  const [isVisible, setIsVisible] = React.useState(false)

  const { data, loading, error, refetch } = useQuery(GET_NOTIFICATION)
  const [sendNotification, { loading: sendPushLoading }] = useMutation(
    SEND_PUSH,
    {
      onCompleted: () => {
        setHeading('')
        setMessage('')
        setType('')
        toast.success('Уведомление создано успешно')
        setIsVisible(false)
        refetch()
      },
    }
  )

  React.useEffect(() => {
    if (!loading && data && !error) {
      const dayjsedArray = data.notifications.map((item) => {
        return {
          ...item,
          created_at: dayjs(item.created_at).format('dd DD MMMM, yyyy | HH:mm'),
        }
      })
      setNotifications(dayjsedArray)
    }
  }, [data, loading, error])

  const handleSendNotification = () => {
    if (heading && message && type) {
      sendNotification({
        variables: {
          input: {
            heading,
            message,
            type,
          },
        },
      })
    } else {
      toast.error('Поля не должны быть пустыми')
    }
  }

  if (loading || sendPushLoading) {
    return <Loading />
  }

  return (
    <Notifications
      {...{
        heading,
        message,
        notifications,
        columns,
        isVisible,
        setIsVisible,
        setMessage,
        setHeading,
        setType,
        handleSendNotification,
      }}
    />
  )
}

export default withMainLayout(NotificationsContainer)
