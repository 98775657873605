import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import 'typeface-roboto'

import './App.css'

import Login from './auth/Login'
import Levels from './levels/Levels.container'
import Level from './levels/Level.container'
import Exercises from './exercises/Exercises.container'
import Payments from './payments/Payments.container'
import Events from './events/Events.container'
import Clubs from './clubs/Clubs.container'
import Club from './club/Club.container'
import Zone from './zone/Zone.container'
import Heros from './heros/Heros.contatiner'
import ProgramSets from './programSets/ProgramSets.container'
import ProgramSet from './programSet/ProgramSet.container'
import Users from './users/Users.container'
import User from './users/User.container'
import Messages from './messages/Messages.container'
import Message from './message/Message.container'
import CreateMessage from './messages/CreateMessage.container'
import EditEvent from '../pages/editEvent/EditEvent.container'
import Awards from '../pages/awards/Awards.container'
import ResetPassword from './resetPassword/ResetPassword.container'
import Seasons from './seasons/Seasons.container'
import Season from './season/Season.container'
import TreasureChests from './treasureChests/TreasureChests.container'
import TreasureChest from './treasureChests/TreasureChest.container'
import Marathons from './marathons/Marathons.container'
import Marathon from './marathon/Marathon.container'
import MarathonEvent from './marathon/MarathonEvent.container'

import UserPhotos from './userPhotos/UserPhotos.container'
import UserPhoto from './userPhotos/UserPhoto.container'

import Contests from './contests/Contests.container'
import Contest from './contests/Contest.container'

import TrainerEvents from './trainerPages/trainerEvents/TrainerEvents.container'
import TrainerEvent from './trainerPages/trainerEvent/TrainerEvent.container'
import TrainerReviews from './trainerPages/trainerReviews/TrainerReviews.container'
import PublicPage from './PublicPage'

import ReceptionEvents from './receptionPages/receptionEvents/ReceptionEvents.container'
import Notifications from './notifications/Notifications.container'

import AllUserPayments from './allUserPayments/AllUserPayments.container'
import AllDroppedRewards from './allDroppedRewards/AllDroppedRewards.container'
import SalesUsers from './salesUsers/SalesUsers.container'
import SpecailBookings from './specialBookings/SpecialBookings.container'
import AutoNotifications from './autoNotifications/AutoNotifications.container'
import CoefficientsCompress from './coefficientsCompress/CoefficientsCompress.container'
import NewsModals from './newsModals/NewModals.container'
import SalesReportContainer from './salesReport/SalesReport.container'
import HeroPathesContainer from './heroPathes/HeroPathes.container'
import ProductsContainer from './products/Products.container'
import ClientsContainer from './clients/Clients.container'
import ConversionContainer from './conversionClients/Conversion.container'
import ConversionMarathonEventsClients from './conversionClients/ConversionMarathonEventsClients'
import FaqContainer from './faq/Faq.container'
import FaqQuestions from './faq/FaqQuestions'

import withHelmet from '../hocs/withHelmet'
import { ProvideAuth } from '../context/useAuth'
import { ProvideLoading } from '../context/useLoading'
import LoadingDialog from '../context/LoadingDialog'
import ProtectedRoute from '../hocs/ProtectedRoute'
import SalesPaymentsContainer from './salesPayments/SalesPayments.container'
import HeroPassesContainer from './heroPasses/HeroPasses.container'
import HeroPassContainer from './heroPass/HeroPass.container'
import PromptDescriptionsContainer from './promptDescriptions/PromptDescriptions.container'
import Logout from './auth/Logout'
import SupportContainer from './support/Support.container'
import SupportCategoriesContainer from './supportCategories/SupportCategories.container'
import SupportSubcategory from './supportCategories/SupportSubcategory'
import HerosWeekContainer from './HerosWeek/HerosWeek.container'

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  headers: {
    authorization: localStorage.getItem('token')
      ? `Bearer ${localStorage.getItem('token')}`
      : null,
  },
})

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  timeout: 100000,
  link: uploadLink,
})

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <ProvideAuth>
        <ProvideLoading>
          <ToastContainer />
          <LoadingDialog />
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<PublicPage />} />
              <Route exact path="/public" element={<PublicPage />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/logout" element={<Logout />} />
              <Route
                exact
                path="/resetPassword/:token"
                element={<ResetPassword />}
              />
              <Route
                exact
                path="/events"
                element={
                  <ProtectedRoute roles={['admin', 'schedule', 'salesperson', 'salesadmin']}>
                    <Events />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/levels"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <Levels />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/levels/:id"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <Level />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/exercises"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <Exercises />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/events/:id"
                element={
                  <ProtectedRoute roles={['admin', 'salesperson', 'schedule', 'salesadmin']}>
                    <EditEvent />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/users"
                element={
                  <ProtectedRoute roles={['admin', 'salesperson', 'salesadmin']}>
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/users/:id"
                element={
                  <ProtectedRoute roles={['admin', 'salesperson', 'salesadmin']}>
                    <User />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/payments"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <Payments />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/clubs"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <Clubs />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/clubs/:id"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <Club />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/clubs/:id/:zoneId"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <Zone />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/heros"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <Heros />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/programSets"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <ProgramSets />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/programSets/:id"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <ProgramSet />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/messages"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <Messages />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/messages/:id"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <Message />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/createMessage"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <CreateMessage />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/awards"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <Awards />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/products"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <ProductsContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/trainerEvents"
                element={
                  <ProtectedRoute roles={['admin', 'trainer']}>
                    <TrainerEvents />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/trainerEvents/:id"
                element={
                  <ProtectedRoute roles={['admin', 'trainer']}>
                    <TrainerEvent />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/trainerReviews"
                element={
                  <ProtectedRoute roles={['admin', 'trainer']}>
                    <TrainerReviews />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/notifications"
                element={
                  <ProtectedRoute roles={['admin', 'salesperson', 'salesadmin']}>
                    <Notifications />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/receptionEvents"
                element={
                  <ProtectedRoute roles={['admin', 'reception', 'salesperson', 'salesadmin']}>
                    <ReceptionEvents />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/allUserPayments"
                element={
                  <ProtectedRoute roles={['admin', 'salesperson', 'salesadmin']}>
                    <AllUserPayments />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/salesUsers"
                element={
                  <ProtectedRoute roles={['admin', 'salesperson', 'salesadmin']}>
                    <SalesUsers />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/clientsContainer"
                element={
                  <ProtectedRoute roles={['admin', 'salesperson', 'salesadmin']}>
                    <ClientsContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/herosWeek"
                element={
                  <ProtectedRoute roles={['admin', 'salesperson', 'salesadmin']}>
                    <HerosWeekContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/treasureChests"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <TreasureChests />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/treasureChests/:id"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <TreasureChest />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/marathons"
                element={
                  <ProtectedRoute roles={['admin', 'salesperson', 'salesadmin']}>
                    <Marathons />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/marathons/:id"
                element={
                  <ProtectedRoute roles={['admin', 'salesperson', 'salesadmin']}>
                    <Marathon />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/marathonEvent/:marathonId/:marathonEventId"
                element={
                  <ProtectedRoute roles={['admin', 'salesperson', 'salesadmin']}>
                    <MarathonEvent />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/allDroppedRewards"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <AllDroppedRewards />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/specialBookings"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <SpecailBookings />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/autoNotifications"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <AutoNotifications />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/coefficientsCompress"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <CoefficientsCompress />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/salesReport"
                element={
                  <ProtectedRoute roles={['admin', 'accountant']}>
                    <SalesReportContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/newsModals"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <NewsModals />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/heroPathes"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <HeroPathesContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/salesPayments"
                element={
                  <ProtectedRoute roles={['admin', 'salesperson', 'salesadmin']}>
                    <SalesPaymentsContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/heroPasses"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <HeroPassesContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/heroPasses/:id"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <HeroPassContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/promptDescriptions"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <PromptDescriptionsContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/conversionContainer"
                element={
                  <ProtectedRoute roles={['admin', 'salesperson', 'salesadmin']}>
                    <ConversionContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/conversionMarathonEventsClients/:itemId"
                element={
                  <ProtectedRoute roles={['admin', 'salesperson', 'salesadmin']}>
                    <ConversionMarathonEventsClients />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="FaqContainer"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <FaqContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/faqQuestions/:itemId"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <FaqQuestions />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="support"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <SupportContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="supportCategories"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <SupportCategoriesContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/supportCategories/:itemId"
                element={
                  <ProtectedRoute roles={['admin']}>
                    <SupportSubcategory />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </ProvideLoading>
      </ProvideAuth>
    </ApolloProvider>
  )
}

const EnhancedApp = withHelmet([{ tag: 'title', content: 'Admin | Hero-app' }])(
  App
)

export default EnhancedApp
